import { map } from 'rxjs/operators';
import { DataService } from 'src/app/services';
import {
  ButtonStyle,
  ModalConfirmComponent
} from './../../../components/system/modal-confirm/modal-confirm.component';
import { ToastrService } from 'ngx-toastr';
import {
  Component,
  OnInit,
  AfterViewInit,
  EventEmitter,
  Input,
  ViewChild,
  OnDestroy,
  Inject,
  forwardRef,
  HostListener,
  Directive
} from '@angular/core';
import {
  WorkflowContextService,
  WorkflowService,
  SecurityService
} from '../../../services';
import {
  ActivityStatus,
  Activity,
  ActivityModel
} from '../../../models/activities';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { Role, Actions, ApplicationStatus } from '../../../models';
import { ApplicationInputComponent } from '../../workflow/application-input/application-input.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, forkJoin, Subject } from 'rxjs';
import { StartApplicationResponse } from '../../../models/start-application-response';
import { PreviousRouteService } from '../../../services/previous-route';
import { ActivityNavigationInfo } from 'src/app/models/activity-navigation-Info';
import { FeeDataEntity } from 'src/app/models/data-entities';
import { DatePipe } from '@angular/common';
import { VoidRequest } from 'src/app/models/voidRequest';
import { GPErrorHandler } from 'src/app/services/gp-error';
// import { URL, URLSearchParams } from 'url';

@Directive()
export class ActivityView {
  @Input() activity: Activity<ActivityModel>;
  @Input() isPreview: boolean;
  isTestApplication: boolean;
  showSave?: EventEmitter<boolean> = new EventEmitter<boolean>();
  showCancel?: EventEmitter<boolean> = new EventEmitter<boolean>();
  showNext?: EventEmitter<boolean> = new EventEmitter<boolean>();
  showPrevious?: EventEmitter<boolean> = new EventEmitter<boolean>();
  goNext?: EventEmitter<boolean> = new EventEmitter<boolean>();
  switchApplication?: EventEmitter<string> = new EventEmitter<string>();
  value$: EventEmitter<any> = new EventEmitter<any>();
  initActivity;
  @Input() form: UntypedFormGroup;
  context: WorkflowContextService;
  nextOnSelect = false;
  applicationId?: string;
  refreshView?: EventEmitter<void> = new EventEmitter<void>();

  async persistChildComponent?() {
    return new Promise(resolve => {
      resolve(null);
    });
  }
  viewTitle(): string {
    if (this.activity && this.activity.model) {
      return this.activity.model.screenName;
    } else {
      return 'unknown';
    }
  }
}

@Component({
  selector: 'wm-app.view',
  templateUrl: './app.view.component.html',
  styleUrls: ['./app.view.component.css']
})
export class AppViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ApplicationInputComponent, { static: true })
  public appInput: ApplicationInputComponent;

  buttonStyles = ButtonStyle;

  @Input() activity: Activity<ActivityModel>;
  @Input() isPreview: boolean;
  @Input() role: Role;
  @Input() form: UntypedFormGroup;
  @ViewChild('newDocument', { static: false }) modal: ModalConfirmComponent;

  applicationId: string;
  workflowName: string;
  applicationNumber: string;
  clientApplicationNumber: string;
  workflowId: string;
  isTestApplication: boolean;
  shouldShowSave = false;
  shouldShowCancel: boolean;
  shouldShowVoid = false;
  shouldShowNext = true;
  shouldShowPrevious = true;
  canUseSave = false;
  canUsePrevious = false;
  canUseNext = false;
  canUseCancelDiscard = false;
  isLastActivity = false;
  processing = false;
  viewTitle: string;
  roleSubscription: Subscription;
  testRoles: Role[];
  selectedRole: string;
  hasFees = false;
  navigationItems: ActivityNavigationInfo[];
  fees: FeeDataEntity[];
  submitterId: string;
  activitySetNextState = false;
  summaryActivities: Activity<ActivityModel>[];
  activityDataId: string;
  returnURL: string;

  loadingExit = false;
  loadingSave = false;
  loadingDiscard = false;

  isMobileDevice = false;
  menuOpen = false;
  now = Date.now();
  pipe = new DatePipe('en-US');

  voidForm: UntypedFormGroup = new UntypedFormGroup({
    voidReason: new UntypedFormControl('', [Validators.required]),
    voidDateTime: new UntypedFormControl({
      value: this.pipe.transform(this.now, 'MM/dd/yyyy h:mm:ss a'),
      disabled: true
    }),
    voidedBy: new UntypedFormControl({
      value: this.context.user ? this.context.user.userName : '',
      disabled: true
    })
  });

  canEditNotes: boolean;
  canViewVersion: boolean;
  application = null;
  canEditApplicationNumber = false;
  applicationProcessing = false;

  constructor(
    @Inject(forwardRef(() => WorkflowContextService))
    public context: WorkflowContextService,
    private _route: ActivatedRoute,
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => DataService))
    private _dataSvc: DataService,
    private _router: Router,
    private _previousRouteSvc: PreviousRouteService,
    private toastr: ToastrService,
    @Inject(forwardRef(() => SecurityService))
    private _securitySvc: SecurityService,
    private _errorHandler: GPErrorHandler
  ) {}
  get voidReason() {
    return this.voidForm.get('voidReason');
  }

  appInputLoaded: boolean = false;

  // here to make sure the child components have loaded before the Next and Save buttons can be enabled
  ngDoCheck(): void {
    if (this.appInput != null && this.appInput.activityContainerLoaded) {
      this.appInputLoaded = true;
    } else {
      this.appInputLoaded = false;
    }
  }

  ngOnDestroy(): void {
    if (this.roleSubscription) {
      this.roleSubscription.unsubscribe();
      this.roleSubscription = null;
    }

    this.context.viewAsRole$.emit(null);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile();
    this.menuOpen = !this.isMobileDevice;
  }

  mainClick() {
    if (this.menuOpen) {
      this.menuOpen = !this.menuOpen;
    }
  }

  ngAfterViewInit() {}

  async persistChildComponent() {
    return this.appInput.persistChildComponent();
  }
  changeActivity(activity: Activity<ActivityModel>) {
    this.processing = false;
    this.activity = activity;
    this.shouldShowNext = true;
    this.shouldShowPrevious = true;
    this.canUsePrevious = this.activity.canGoPrevious; // this.activity.previousActivityId != null;
    this.canUseNext = this.activity.canGoNext; // true; // this is here to reset the next button and let the next activity control it from there.
    this.canUseCancelDiscard = true;
    this.canUseSave =
      !(this.isPreview || false || this.isTestApplication || false) &&
      this.activity.status !== ActivityStatus.Completed;
    this.shouldShowSave = this.canUseSave || this.isTestApplication || false;
  }

  refreshUser() {
    if (this.context.user) {
      this._securitySvc.getUser(this.context.user.id).subscribe(user => {
        this.context.user$.emit(user);
      });
    }
  }

  retrieveSummary() {
    this.summaryActivities = null;
    this._workflowSvc
      .getCompletedActivities(this.applicationId)
      .subscribe(activities => {
        this.summaryActivities = activities;
      });
  }

  retrieveApplication() {
    this.application = null;
    this._securitySvc
      .isLoginEntitled(Actions.WORKFLOW_APPLICATIONS_EDIT)
      .subscribe(result => {
        this.canEditNotes = result;
      });
    return this._dataSvc.getApplicationSummary(this.applicationId).pipe(
      map(summary => {
        this.application = summary.application;
        return summary;
      })
    );
  }

  openApplicationWorkflowBuilder() {
    this.retrieveApplication().subscribe(summary => {
      const workflowId = summary.application.workflowVersion.workflowId;
      const versionId = summary.application.workflowVersionId;

      this.modal.cancel();

      this._router.navigate([
        '/admin/workflow-builder/',
        workflowId,
        versionId,
        this.application.id
      ]);
    });
  }

  changeRole(e) {
    if (this.context.viewAsRole !== e) {
      this.context.viewAsRole$.emit(
        this.testRoles.find(
          r => r.id.toLowerCase() === this.selectedRole.toLowerCase()
        )
      );
    }
  }

  disableButtons() {
    this.canUsePrevious = false;
    this.canUseNext = false;
    this.canUseCancelDiscard = false;
    this.canUseSave = false;
  }

  navigate(activityDataId: string) {
    this.processing = true;
    this.disableButtons();
    const navAD = this.navigationItems.find(
      ni =>
        ni.activityDataId &&
        ni.activityDataId.toLowerCase() == activityDataId.toLowerCase()
    );
    if (navAD) {
      if (navAD.canNavigate) {
        this.appInput.navigateToActivity(activityDataId);
      } else {
        this.toastr.warning(
          `You are unable to view activity '${navAD.activityLabel}' at this time.`
        );
      }
    } else {
      this.toastr.warning('The activity you requested is not available.');
    }
  }

  getFormGroupErrors(fg: UntypedFormGroup): string[] {
    let errors: string[];

    if (fg.errors) {
      const keys = Object.keys(fg.errors);

      errors = keys.map((value, index) => {
        return '';
      });
    }

    return errors;
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  nexter() {
    this.persistChildComponent().then(value => {});
  }

  next() {
    this.persistChildComponent().then(value => {
      if (this.appInput && this.appInput.form.invalid) {
        this.validateAllFormFields(this.appInput.form);
      } else if (this.appInput && (this.appInput.form.valid || this.appInput.form.disabled)) {
        this.processing = true;
        // reset the nav buttons and let the next activity control it from here
        this.disableButtons();

        this.appInput.committed(this.activity).subscribe(a => {
          if (a.refreshUser) {
            this.refreshUser();
          }

          this.applicationNumber = a.applicationNumber;
          this.clientApplicationNumber = a.clientApplicationNumber;
        });
      }
    });
  }

  save(exit = true) {
    this.loadingSave = true;
    this.disableButtons();
    this.persistChildComponent().then(value => {
      const nav = () => {
        if (exit) {
          this.navigateToReferrer();
        } else {
          this.loadingSave = false;
          this.processing = true;
          this.loadApplication(this.applicationId);
        }
      };

      if (this.activity && this.activity.canEdit) {
        this.appInput.saved(this.activity).subscribe(response => {
          if (response.saveSuccess) {
            this.toastr.success('Saved!', null, {
              positionClass: 'toast-top-center'
            });

            nav();
          } else {
            const saveFailTitle = 'Could not save activity.';

            if (response.loadApplicationSummary) {
              this.toastr.info(
                `This application has been ${
                  ApplicationStatus[response.applicationStatus]
                }.`,
                saveFailTitle,
                { disableTimeOut: true }
              );

              // load current activity
              nav();
            } else if (response.longApplicationProcessEncountered) {
              this.toastr.info(
                'Due to another action taken, this application is currently processing.  Please wait while the process finishes.  Note that depending on the nature of the action that was already processing, it is possible this application may no longer be In Progress when that action finishes.',
                saveFailTitle,
                { disableTimeOut: true }
              );

              // load current activity
              nav();
            } else if (response.activityWasCompleted) {
              this.toastr.info(
                'Another action taken in this application has completed this activity.  The current data is now showing.',
                saveFailTitle,
                { disableTimeOut: true }
              );

              // load this activity in its current state
              this.navigate(this.activity.activityDataId);
            } else if (response.activityWasCanceled) {
              this.toastr.info(
                'The activity you tried to save has been canceled by another action taken in this application.',
                saveFailTitle,
                { disableTimeOut: true }
              );

              // load current activity
              nav();
            } else {
              // uncategorized save failure on server
              this.toastr.info(
                'Please contact support if this issue persists.',
                saveFailTitle,
                { disableTimeOut: true }
              );

              // load this activity in its current state
              this.navigate(this.activity.activityDataId);
            }
          }
        });
      } else {
        nav();
      }
    });
  }

  saveNotes() {
    const internalNotes: string = this.application.internalNotes;
    const externalNotes: string = this.application.externalNotes;

    this._workflowSvc
      .saveWorkflowApplicationNotes(
        this.applicationId,
        internalNotes,
        externalNotes
      )
      .subscribe(() => {
        this.toastr.success('Application Notes Saved');
      });
  }

  navigateToReferrer() {
    let returnURL = this.returnURL || this.context.applicationReturnURL || '';
    const params = {};
    try {
      const url: URL = new URL(`${location.origin}${returnURL}`);
      const sp: URLSearchParams = url.searchParams;
      sp.forEach((value, key) => {
        params[key] = value;
      });
      returnURL = url.pathname;
    } catch (err) {}

    if (
      returnURL !== '' &&
      returnURL.indexOf('/application/workflow-application/') === -1
    ) {
      this._router.navigate([returnURL], { queryParams: params });
    } else {
      this._router.navigate(['/jurisdiction', this.context.client.id]);
    }
  }

  previous() {
    this.persistChildComponent().then(value => {
      this.disableButtons();
      this.processing = true;
      this.appInput.goPrevious(this.activity);
    });
  }

  exitTestApplication() {
    this.loadingExit = true;
    this._workflowSvc
      .cleanupTestApplication(this.context.applicationId)
      .subscribe(
        a => {
          if (this.roleSubscription) {
            this.roleSubscription.unsubscribe();
            this.roleSubscription = null;
          }
          this.context.applicationId$.emit(null);
          this.context.viewAsRole$.emit(null);
          this.loadingExit = false;
          this.navigateToReferrer();
        },
        err => {
          this.loadingExit = false;
          this._errorHandler.handleError(err);
        }
      );

    return false;
  }

  cancel() {
    this.loadingDiscard = true;
    this.disableButtons();

    this._workflowSvc
      .cancelApplications(this.context.applicationId)
      .subscribe(o => {
        this.refreshUser();
        this.loadingDiscard = false;
        if (o) {
          this._router.navigate(['/jurisdiction', this.context.client.id]);
        }
      });
  }

  void() {
    this.disableButtons();
    const voidReason: string = this.voidForm.get('voidReason').value;
    const voidDateTime = this.voidForm.get('voidDateTime').value;
    const voidedBy: string = this.context.user.id;

    const voidRequest = new VoidRequest({
      voidReason: voidReason,
      voidDateTime: voidDateTime,
      voidedByUser: voidedBy
    });
    this._workflowSvc
      .voidApplications(this.context.applicationId, voidRequest)
      .subscribe(o => {
        if (o) {
          this.navigateToReferrer();
        }
      });
  }
  isMobile() {
    this.isMobileDevice = window.innerWidth < 768;
  }

  canVoidChanged(e: { canVoid: boolean; canDiscard: boolean }) {
    const canVoid = e.canVoid;
    const canEdit = !this.activity ? false : this.activity.canEdit;
    const canDiscard = e.canDiscard;

    this.shouldShowVoid = canVoid && canEdit;

    this.shouldShowCancel = !canVoid && canDiscard && canEdit;
  }

  canUseNextChanged(e) {
    this.canUseNext = e;
    this.activitySetNextState = true;
  }
  loadApplication(applicationId: string) {
    this.applicationId = applicationId;
    this.context.applicationId$.emit(this.applicationId);
    this.appInput.applicationId = this.applicationId;

    const loadCurrent = () =>
      this.appInput.loadCurrentActivity().subscribe(a => {
        if (a) {
          this.isTestApplication = a.isTestApplication;
          if (!a.isProcessing) {
            this.context.client$.emit(a.client);
            this.navigationItems = a.navigationItems;

            if (this.activityDataId) {
              this.navigate(this.activityDataId);
            }
            this.isTestApplication = a.isTestApplication;
            this.submitterId = a.submitterId;
            this.workflowName = a.workflowName;
            this.applicationNumber = a.applicationNumber;
            this.clientApplicationNumber = a.clientApplicationNumber;
            if (this.isTestApplication) {
              this._workflowSvc
                .getWorkflowRoles(a.workflowId)
                .subscribe(roles => {
                  this.testRoles = roles;

                  if (
                    !this.testRoles.find(
                      r =>
                        r.id.toLowerCase() ===
                        SecurityService.APPLICANT_ROLE.id.toLowerCase()
                    )
                  ) {
                    this.testRoles.splice(0, 0, SecurityService.APPLICANT_ROLE);
                  }

                  if (roles) {
                    this.testRoles.forEach(r => {
                      r.id = r.id.toLowerCase();
                    });
                  }

                  this.selectedRole =
                    (this.context.viewAsRole
                      ? this.context.viewAsRole.id
                      : a.activity
                      ? a.activity.model.responsibleRoleId
                      : null) ||
                    SecurityService.APPLICANT_ROLE.id.toLowerCase();

                  if (this.roleSubscription) {
                    this.roleSubscription.unsubscribe();
                    this.roleSubscription = null;
                  }

                  this.changeRole(this.selectedRole);

                  this.roleSubscription = this.context.viewAsRole$.subscribe(
                    value => {
                      if (value != null) {
                        this.appInput.loadCurrentActivity().subscribe(ap => {
                          if (ap) {
                            this.isTestApplication = ap.isTestApplication;
                            this.navigationItems = ap.navigationItems;
                            this.fees = ap.fees;
                            this.hasFees = ap.hasFees;
                          }
                        });
                      }
                    }
                  );
                });
            }

            this.shouldShowNext = true;
            this.shouldShowPrevious = true;
            this.canUseCancelDiscard = true;
            this.shouldShowSave = true;
            if (!this.activitySetNextState) {
              this.canUseNext = true;
            }
            this.canUseSave = !(
              this.isPreview ||
              false ||
              this.isTestApplication || false
            );
            return;
          } else {
            // application exists but is currently processing activities so we have to keep checking until it is idle.
            // wait 1 second and check again
            setTimeout(() => {
              loadCurrent();
            }, 1000);

            this.shouldShowNext = false;
            this.shouldShowPrevious = false;
            this.canUseNext = false;
            this.canUseCancelDiscard = false;
            this.canUseSave = false;
            return;
          }
        } else {
          // we can't find the application so we should just tell the user and disable controls
          this.toastr.error(
            'We were unable to find the workflow application you requested.'
          );
        }
      });

    loadCurrent();
  }

  ngOnInit() {
    this.isMobile();
    this.processing = true;
    const previousURL = this._previousRouteSvc.getPreviousUrl() || '';
    this._route.queryParams.subscribe(parms => {
      this.returnURL = parms['returnURL'];
    });

    this._securitySvc
      .isLoginEntitled(Actions.VIEW_APPLICATION_VERSION)
      .subscribe(result => {
        this.canViewVersion = result;
      });
    this._securitySvc
      .isLoginEntitled(Actions.EDIT_APPLICATION_NUMBER)
      .subscribe(result => {
        this.canEditApplicationNumber = result;
      });

    this._route.params.subscribe(params => {
      this.workflowId = params['workflowId'];
      this.isTestApplication = params['isTest'];
      this.activityDataId = params['activityDataId'];

      // determine the previous URL
      this._route.url.subscribe(value => {
        const currentURL = '/' + value.join('/');

        if (currentURL !== (previousURL || '')) {
          this.context.applicationReturnURL$.emit(previousURL);
        }
      });
      this.loadApplication(params['applicationId']);
    });
  }

  changeButtonAvailability(e: boolean) {
    this.applicationProcessing = e;
  }
}
